
    import LeftContainer from './leftContainer/LeftContainer.vue';
    import TagSearch from '~/components/child/tag/TagSearch.vue';
    import MarqeenContainer from '~/components/child/marqeen/MarqeenContainer.vue';
    import TextBubbleContainer from '~/components/child/text_bubble/TextBubbleContainer.vue';
    import IndexTabContainer from '~/components/child/index_tab/IndexTabContainer.vue';
    import ArticleHotIndexContainer from '~/components/child/article/ArticleHotIndexContainer.vue';
    import PostShortArticle from '~/components/child/post/PostShortArticle.vue';
    import NewIndexTabContainer from '~/components/child/index_tab/NewIndexTabContainer.vue';
    export default {
        name: "main-container",
        components: { LeftContainer, TagSearch, MarqeenContainer, TextBubbleContainer, IndexTabContainer, ArticleHotIndexContainer, PostShortArticle, NewIndexTabContainer },
        props: {
            pa_page_name: {
                type: String
            }
        },
    }
