
import TagIndexContainer from '~/components/child/tag/TagIndexContainer.vue';
import BloggerRanking from '~/components/child/blogger/BloggerRanking.vue';
import AdvertiseContainer from '~/components/child/advertise/AdvertiseContainer.vue';
import ArticleListCardContainer from '../../child/article/ArticleListCardContainer.vue';
import PixstarContainer from '~/components/event/PixstarContainer.vue';
import BloggerRankingLoadingContainer from '~/components/child/loading/container/BloggerRankingLoadingContainer.vue';

export default {
    name: "right-container",
    components: {
        TagIndexContainer,
        BloggerRanking,
        AdvertiseContainer,
        ArticleListCardContainer,
        PixstarContainer,
        BloggerRankingLoadingContainer
    },
    computed: {
        tab_id() {
            return this.$store.state.index_tab.tab_id;
        },
        window_width() {
            return this.$store.state.index_tab.window_width;
        },
        all_blogger() {
            return this.$store.state.blogger.blogger_ranking_data;
        },
    },
    mounted() {
    }
}
