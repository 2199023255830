
export default {
    name: "article-hot-index-card",
    props: {
        article: Object,
        pa_count: Number,
        article_idx: Number
    },
    methods:{
        paFunction(){
            let pa_idx = (this.pa_count + (this.article_idx + 1)/10000).toFixed(4);
            let reg_article_link = "http://"+ this.article.author.user_name +".pixnet.net/blog/post/" + this.article.id;
            window._piq.push([
                'trackEvent',
                'rs2022::十大頻道::首頁',
                'imps::本日熱門::article_card::new_article',
                `文章::${reg_article_link}`,
                pa_idx
            ]);
        },
        paClickFunction(){
            let pa_idx = (this.pa_count + (this.article_idx + 1)/10000).toFixed(4);
            let reg_article_link = "http://"+ this.article.author.user_name +".pixnet.net/blog/post/" + this.article.id;
            window._piq.push([
                'trackEvent',
                'rs2022::十大頻道::首頁',
                'click::本日熱門::article_card::new_article',
                `文章::${reg_article_link}`,
                pa_idx
            ]);
        },
        visibilityChanged(isVisible, entry) {
            this.isVisible = isVisible
            if (entry.intersectionRatio > 0) {
                this.paFunction()  
            }
        }
    }
}
