
export default {
    name: "tag-capsule",
    props: {
        item: {
            type: Object
        },
        idx: {
            type: Number
        },
        pa_page_name: {
            type: String
        }
    },
    data() {
        return {
            pa_label_list: {
                '首頁': '最近好多人搜尋',
                '旅遊/國外': '熱搜好處去',
                '旅遊/國內': '熱搜好處去',
                '美食': '超熱搜話題',
                '影視': '超熱搜話題',
                '親子': '超熱搜話題',
                '美妝': '超熱搜話題',
                '3C': '超熱搜話題',
                '身心靈': '超熱搜話題',
                '寵物': '超熱搜話題',
                '健康': '超熱搜話題',
                '職涯': '超熱搜話題',
                '住家': '超熱搜話題'
            }
        }
    },
    computed: {
        model_id() {
            return this.$store.state.tag.all_site_model_id;
        },
        router_name() {
            return this.$route.name;
        },
    },
    methods: {
        paFunction(tag, idx) {
            let pa_idx = 1 + idx/10000;
            let pa_label = this.pa_label_list[this.pa_page_name];
            window._piq.push(['trackEvent', `rs2022::十大頻道::${this.pa_page_name}`, `click::${pa_label}::hashtag_card::${this.model_id}`, `${pa_label}::${tag}`, pa_idx]);
        }
    }
}
