
import { mapActions } from 'vuex';
import FalconAd from '@/components/common/widget/FalconAd.vue';


export default {
    name: "tag-index-container",
    components: {
        FalconAd
    },
    data() {
        return {
            isVisible: true,
            pixAd4697: null,
            pixAdSticky: false
        }
    },
    computed: {
        tag_data() {
            return this.$store.state.tag.index_tag_data;
        },
        model_id() {
            return this.$store.state.tag.searchtext_model_id;
        }
    },
    methods: {
        ...mapActions('tag', [
            'getAllSiteTagData'
        ]),
        paFunction(text, idx) {
            let pa_idx = 1 + (idx + 1) / 10000;
            window._piq.push(['trackEvent', 'rs2022::十大頻道::首頁', 'imps::熱搜關鍵字::hashtag_card::' + this.model_id, '熱搜關鍵字::' + text, pa_idx]);
        },
        paClickFunction(text, idx) {
            let pa_idx = 1 + (idx + 1) / 10000;
            window._piq.push(['trackEvent', 'rs2022::十大頻道::首頁', 'click::熱搜關鍵字::hashtag_card::' + this.model_id, '熱搜關鍵字::' + text, pa_idx]);
        },
        visibilityChanged(isVisible, entry) {
            this.isVisible = isVisible
            if (entry.intersectionRatio > 0) {
                this.tag_data.forEach((ele, index) => {
                    this.paFunction(ele.name, index);
                });
            }
        },
        pixAd4697Stick() {
            this.pixAd4697 = this.$refs.pixAd;
            window.addEventListener('scroll', (event) => {
                const scrollPosition = window.scrollY || document.documentElement.scrollTop;
                // #97178 河道高度不夠時會因為sticky反覆切換抖動 -500是讓sticky晚一點加上
                this.pixAdSticky = this.pixAd4697.offsetTop <= scrollPosition - 700;
            });
        }
    },
    async fetch() {
        await this.getAllSiteTagData();
    },
    mounted() {
        this.pixAd4697Stick();
    }
}
