
export default {
    name: "ga-container",
    props: ['ga_number'],
    methods: {
        gtag() {
            window.dataLayer.push(arguments);
        },
        activeGA() {
            this.gtag("js", new Date());
            this.gtag("config", this.ga_number);
        },
    },
    mounted() {
        this.activeGA();
    }
}
