
import axios from "axios";
import { mapMutations } from 'vuex'; 
export default {
    name: "short-article-report",
    data(){
        return {
            report_item: [
                { report_reason_id: 2, report_reason_text: "文章內容空泛或明顯無意義內容" },
                { report_reason_id: 8, report_reason_text: "垃圾內容或內容與標題不符" },
                { report_reason_id: 14, report_reason_text: "仇恨或惡意內容" },
                { report_reason_id: 4, report_reason_text: "違反善良風俗" },
                { report_reason_id: 10, report_reason_text: "這是廣告、商業宣傳" },
                { report_reason_id: 16, report_reason_text: "包含裸露、色情、性騷擾等讓人不舒服之內容" },
                { report_reason_id: 18, report_reason_text: "暴力、傷害他人／自己或傷害動物" },
                { report_reason_id: 20, report_reason_text: "宣傳恐怖主義" },
                { report_reason_id: 22, report_reason_text: "販售違禁品" },
            ],
            reason_id: 2
        }
    },
    computed: {
        open_report(){
            return this.$store.state.report.report_open;
        },
        article_id() {
            return this.$store.state.report.blog_article_id;
        },
        article_link() {
            return this.$store.state.report.blog_article_link;
        },
        article_uniqid() {
            return this.$store.state.report.blog_article_member_id;
        },
        member_uniqid() {
            return this.$store.state.login.member_data.member_uniqid;
        },
        member_token() {
            return this.$store.state.login.pix_member_token.member_token;
        },
        article_ref() {
            return "_"+this.article_id;
        }
    },
    methods: {
        ...mapMutations('report', [
            'toggleReport',
            'updateArticleId',
            'updateArticleLink',
            'toggleReportResponse'
        ]),
        closeReport(){
            this.toggleReport();
        },
        reportArticle(){
            const ref_num = this.article_ref;
            const service = 'personal-post';
            const report_reason_id = this.reason_id;
            const comment_id = this.article_uniqid + "-personal://members/" + this.article_uniqid + "/articles/" + this.article_id + "-" + this.article_id;
            const header = { 'Authorization': "Bearer "+ this.member_token, 'Content-Type': 'application/json' }
            const API_url = "https://www.pixnet.net/mainpage/api/reports";
            const req = axios.post(API_url, { report_reason_id: report_reason_id,  comment_id: comment_id, service: service }, { headers: header });
            req.then((e) => {
                if(!e.data.error && e.status === 200) {
                    this.toggleReport();
                    this.toggleReportResponse();
                    this.$parent.$refs[ref_num][0].disableThisArticle();
                }
            })
            .catch((e) => {
                console.log(e)
            })
        },
        // 後續再做
        // paClickSendFunction() {
        //     window._piq.push([
        //         'trackEvent',
        //         `rs2022::十大頻道::首頁`,
        //         `report::動態::post_card::user_action`,
        //         `動態::發布`,
        //         ``
        //     ]);
        // }
    },
    mounted() {
    },
}
