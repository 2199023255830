
import { mapActions, mapMutations } from 'vuex';
import ToastContainer from '../lightbox/ToastContainer.vue';
import ArticleCard from './ArticleCard.vue';
import ArticleReport from './lightbox/ArticleReport.vue';
import ArticleReportResponse from './lightbox/ArticleReportResponse.vue';
import ArticleShare from './lightbox/ArticleShare.vue';
import ArticleLoadingContainer from '../loading/container/ArticleLoadingContainer.vue';

export default {
    name: "article-container",
    components: {
        ArticleCard,
        ArticleReport,
        ArticleShare,
        ArticleReportResponse,
        ToastContainer,
        ArticleLoadingContainer
    },
    data() {
        return {
            article_page: 2,
            pa_count: 1,
        }
    },
    methods: {
        ...mapActions('article', [
            'getArticleData',
            'getMoreArticleData',
        ]),
        ...mapMutations('article', [
            'toogleLazyLoadingStatus'
        ]),
        moreArticleButton() {
            // 無限卷軸，暫時不用
            const ele = document.querySelector("#index-more");
            const callback = (e) => {
                if(window.innerHeight > ele.getBoundingClientRect().top) {
                    const token = this.member_token ? this.member_token : "";
                    const params = { 
                        channel_id: 0, 
                        page: this.article_page,
                        page_size: 20,
                        with_author: 1,
                        with_images: 1,
                        member_token: token
                    };
                    this.getMoreArticleData(params)
                    this.article_page = this.article_page + 1;
                }
                
            }
            const option = {
                root: null,
                rootMargin: "0px"
            }
            const observer = new IntersectionObserver(callback, option);
            observer.observe(ele);
        },
        clickToGetMoreArticle(){
            this.toogleLazyLoadingStatus();
            const token = this.member_token ? this.member_token : "";
            const params = { 
                channel_id: 0, 
                page: this.article_page,
                page_size: 20,
                with_author: 1,
                with_images: 1,
                member_token: token
            };
            this.getMoreArticleData(params);
            this.article_page = this.article_page + 1;
            this.pa_count = this.pa_count + 1;
        },
        openToast(){
            this.$refs.toast_box.openToastElement();
        }
    },
    computed: {
        article_data() {
            return this.$store.state.article.article_data;
        },
        member_token() {
            return this.$store.state.login.pix_member_token.member_token;
        },
        article_model_id_index() {
            return this.$store.state.article.model_id_index;
        },
        lazy_loading_status() {
            return this.$store.state.article.lazy_loading_status;
        }
    },
    async mounted(){
        const token = this.member_token ? this.member_token : "";
        const params = { 
            channel_id: 0, 
            page: 1,
            page_size: 20,
            with_author: 1,
            with_images: 1,
            member_token: token
        };
        await this.getArticleData(params);
    }
}
