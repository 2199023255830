
import { mapMutations } from 'vuex'; 
    export default {
        name: "index-tab-container",
        data(){
            return {
                showAnimation: false
            }
        },
        computed: {
            tab_id() {
                return this.$store.state.index_tab.tab_id;
            },
            tab_left() {
                return this.$store.state.index_tab.tab_highlight_style.left;
            },
            tab_width() {
                return this.$store.state.index_tab.tab_highlight_style.width;
            },
            window_width() {
                return this.$store.state.index_tab.window_width;
            }
        },
        methods: {
            ...mapMutations('index_tab', [
                'updateTabId',
                'updateTabLeft',
                'updateTabWidth',
                'updateWindowWidth'
            ]),
            activeTab(id) {
                if (this.tab_id != id) {
                    this.setTabHighLighter(id, this.tab_id);
                } 
            },
            countTabLeft(){
                return this.$refs.index_tab.getBoundingClientRect().left;
            },
            initTabHighLighter(id){
                const width = this.$refs.index_tab.children[id - 1].clientWidth;
                const left = this.$refs.index_tab.children[id - 1].getBoundingClientRect().left;
                const tab_left = this.countTabLeft();
                this.updateTabLeft(left - tab_left);
                this.updateTabWidth(width);
            },
            resizeTabHighLighter(id) {
                setTimeout(()=>{
                    if(this.$refs.index_tab.children[id - 1] != undefined) {
                        const width = this.$refs.index_tab.children[id - 1].clientWidth;
                        const left = this.$refs.index_tab.children[id - 1].getBoundingClientRect().left;
                        const tab_left = this.countTabLeft();
                        this.updateTabLeft(left - tab_left);
                        this.updateTabWidth(width); 
                    }
                }, 100)
            },
            setTabHighLighter(id, origin_id){
                const active_img_width = 27;
                const width = this.$refs.index_tab.children[id - 1].clientWidth;
                const left = this.$refs.index_tab.children[id - 1].getBoundingClientRect().left;
                const tab_left = this.countTabLeft();
                if (id == 1 || id < origin_id) {
                    this.updateTabLeft(left - tab_left);
                } else {
                    this.updateTabLeft(left - tab_left - active_img_width);
                }
                this.updateTabWidth(width + active_img_width);
                this.updateTabId(id);
            },
            setShowAnimation(){
                setTimeout(()=>{
                    this.showAnimation = true;
                }, 500)
            },
            setWindowWidth() {
                this.updateWindowWidth(window.innerWidth)
            },
            replaceTabWhenResizeWindow(){
                if(this.tab_id > 2 && this.window_width > 1024) {
                    this.activeTab(1);
                }
            },
            executeWhenResize(e) {
                this.setWindowWidth();
                this.replaceTabWhenResizeWindow();
                this.resizeTabHighLighter(this.tab_id);
            }
            
        },
        mounted() {
            window.addEventListener("resize", this.executeWhenResize);
            this.initTabHighLighter(this.tab_id);
            this.setShowAnimation();
            this.setWindowWidth();
            this.countTabLeft();
        }
    }
