
import { mapActions, mapMutations } from 'vuex';
import PostAndEditShortArticleModule from './PostAndEditShortArticleModule.vue';
import ToastContainer from '../lightbox/ToastContainer.vue';
import axios from "axios";
export default {
    name: 'post-short-article',
    components: {
        PostAndEditShortArticleModule,
        ToastContainer
    },
    computed: {
        is_login() {
            return this.$store.state.login.login_status;
        },
        member_data() {
            return this.$store.state.login.member_data;
        },
        member_thumb() {
            return this.$store.state.login.member_data.member_thumb;
        },
        member_token() {
            return this.$store.state.login.pix_member_token.member_token;
        },
        open_post_block() {
            return this.$store.state.short_article.open_post_block;
        },
    },
    methods: {
        ...mapActions('short_article', [
            'getShortArticleData'
        ]),
        ...mapMutations('short_article', [
            'toggleOpenPostBlock',
            'setPostMode'
        ]),
        createNewShortArticle() {
            this.toggleOpenPostBlock();
            this.setPostMode('create');
        },
        goLogin(){
            this.paClickSendFunction();
            const url = window.location.host;
            location.href = "https://member.pixnet.cc/login/openid?openid=https://member.pixnet.cc/login&easy_login=1&done=https%3A%2F%2F"+ url +"%3Fversions%3D2023";
        },
        openToast(){
            this.$refs.toast_box.openToastElement();
        },
        paClickSendFunction() {
            window._piq.push([
                'trackEvent',
                `rs2022::十大頻道::首頁`,
                `click::前往登入::button::user_action`,
                `短貼文::前往登入`,
                ``
            ]);
        }
    },
    mounted(){
    }
}
