
    import { mapActions } from 'vuex';
    import MarqeenContent from './MarqeenContent.vue';
    export default {
        name: "marqeen-container",
        components: { MarqeenContent },
        methods: {
            ...mapActions('marqeen', [
                'getMarqeenData'
            ])
        },
        computed: {
            marqeen_data() {
                return this.$store.state.marqeen.marqeen_data;
            }
        },
        async fetch(){
            const params = { id: 1 };
            await this.$store.dispatch("marqeen/getMarqeenData", params);
        }
    }
