
import ArticleContainer from '~/components/child/article/ArticleContainer.vue';
import MixArticleContainer from '~/components/child/article/MixArticleContainer.vue';
import ShortArticleContainer from '~/components/child/article/ShortArticleContainer.vue';
import BloggerLoginContainer from '~/components/child/blogger/BloggerLoginContainer.vue';
import VideoIndexContainer from '~/components/child/video/VideoIndexContainer.vue';
import FalconAd from '~/components/common/widget/FalconAd.vue';

export default {
    name: "left-container",
    components: {
        ArticleContainer,
        BloggerLoginContainer,
        FalconAd,
        ShortArticleContainer,
        MixArticleContainer,
        VideoIndexContainer
    },
    
    computed: {
        tab_id() {
            return this.$store.state.index_tab.tab_id;
        },
        login_check() {
            return this.$store.state.login.login_status && window.pix.member_token;
        }
    },
    methods:{
        rebootPixAd() {
            if(window.PIXFalcon){
                var pixAd;
                pixAd = new PIXFalcon();
                pixAd.placeAds(document);
            }
        }
    },
    data() {
        return {
            isMobile: false
        }
    },
    mounted() {
        this.isMobile = window.screen.width < 768;
        this.rebootPixAd();
    }
}
