
export default {
    name: "collection-card",
    props: {
        article: Object,
    },
    methods: {
        imgError(img) {
            img.src = require(`~/static/img/public/default/img_default_`+ Math.floor(Math.random()*3) +`.png)`);
        }
    }
};
