
    export default {
        name: "marqeen-content",
        props: {
            item: {
                type: Object
            },
            rank: {
                type: Number
            }
        }
    }
