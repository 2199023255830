
import { mapMutations } from 'vuex'; 
export default {
    name: "new-index-tab-container",
    computed: {
        tab_id() {
            return this.$store.state.index_tab.tab_id;
        },
    },
    methods: {
        ...mapMutations('index_tab', [
            'updateTabId',
            'updateWindowWidth'
        ]),
        activeTab(id) {
            if (this.tab_id != id) {
                this.updateTabId(id);
            } 
            history.replaceState({}, "", `/?main_tab=${id}`);
        },   
        setWindowWidth() {
            this.updateWindowWidth(window.innerWidth)
        },
        setTabByQuery() {
            const queryMainTab = this.$route.query.main_tab;
            if (queryMainTab && Number(queryMainTab) > 0 && Number(queryMainTab) <= 3) {
                this.updateTabId(queryMainTab);
            }
        }
    },
    mounted() {
        window.addEventListener("resize", this.setWindowWidth);
        this.setWindowWidth();
        this.setTabByQuery();
    }
}
