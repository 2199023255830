
import hotBloggerCacheData from "static/json/hot_blogger_cache.json";
import newBloggerCacheData from "static/json/new_blogger_cache.json";
import { mapActions } from 'vuex';
import BloggerRankingLoadingContainer from '../loading/container/BloggerRankingLoadingContainer.vue';
export default {
    name: "blogger-ranking",
    data() {
        return {
            rank_status: 1,
            params: { page_size: 5, with_blog: 1, with_counter: 1, with_articles: 1, with_profile: 1 },
            pa_text: "熱門",
            isVisible: true,
            cache_data_hot: hotBloggerCacheData,
            cache_data_new: newBloggerCacheData
        };
    },
    computed: {
        top_one() {
            return this.$store.state.blogger.blogger_top_rank;
        },
        other_blogger() {
            return this.$store.state.blogger.blogger_ranking_data.slice(1);
        },
        all_blogger() {
            return this.$store.state.blogger.blogger_ranking_data;
        },
        model_id() {
            return this.$store.state.blogger.blogger_top_rank_model_id;
        },
        // top_one() {
        //     if (this.rank_status == 1) {
        //         return this.cache_data_hot[0];
        //     } else {
        //         return this.cache_data_new[0];
        //     }  
        // },
        // other_blogger() {
        //     if (this.rank_status == 1) {
        //         return this.cache_data_hot.slice(1);
        //     } else {
        //         return this.cache_data_new.slice(1);
        //     }
        // },
    },
    methods: {
        ...mapActions('blogger', [
            'getBloggerTopRankingData',
            'getBloggerNewRankingData'
        ]),
        async switchBloggerRank(tab_id) {
            if (this.rank_status != tab_id) {
                this.rank_status = tab_id;
                if (this.rank_status == 1) {
                    this.pa_text = "熱門";
                     await this.$store.dispatch("blogger/getBloggerTopRankingData", this.params);
                }
                else {
                    this.pa_text = "新星";
                    await this.$store.dispatch("blogger/getBloggerNewRankingData", this.params);
                }
            }
        },
        formatteNumber(num) {
            const formatter = new Intl.NumberFormat('zh-Hant-TW', {
                /** decimal：純數字 currency：貨幣 percent：百分比 unit：單位 */
                style: 'decimal',
                minimumFractionDigits: 0
            });
            return formatter.format(num);
        },
        paFunction(blog_data, index) {
            window._piq.push(['trackEvent', 'rs2022::十大頻道::首頁', 'imps::部落客排行::blogger_card::' + this.model_id, '部落客排行/' + this.pa_text + '::' + blog_data, '1.000' + index]);
        },
        paClickFunction(blog_data, index) {
            window._piq.push(['trackEvent', 'rs2022::十大頻道::首頁', 'click::部落客排行::blogger_card::' + this.model_id, '部落客排行/' + this.pa_text + '::' + blog_data, '1.000' + index]);
        },
        visibilityChanged(isVisible, entry) {
            this.isVisible = isVisible;
            if (entry.intersectionRatio > 0 && this.all_blogger.length > 0) {
                for (let i = 0; i < 5; i++) {
                    let blogger_text = this.all_blogger[i]?.displayname + "/" + this.all_blogger[i]?.articles?.user;
                    this.paFunction(blogger_text, i + 1);
                }
            }
        }
    },
    async fetch() {
        await this.getBloggerTopRankingData(this.params);
    },
    components: { BloggerRankingLoadingContainer },
    mounted() {
        // console.log(this.cache_data_hot)
    }
}
