
    export default {
        name: "text-bubble-container",
        data() {
            return {
                placeholder: "想說些什麼..."
            }
        },
        computed: {
            member_name() {
                return this.$store.state.login.member_name;
            },
            is_login() {
                return this.$store.state.login.login_status;
            }
        },
        mounted() {
        }
    }
