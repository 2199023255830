
    import { mapActions } from 'vuex';
    import TagAllSiteContainer from './TagAllSiteContainer.vue';
    
    export default {
        name: "tag-search",
        components: { TagAllSiteContainer },
        props: {
            pa_page_name: {
                type: String
            }
        },
        computed: {
            search_word() {
                return this.$store.state.online_data.keyword_search;
            },
            model_id() {
                return this.$store.state.tag.all_site_model_id;
            }
        },
        methods: {
            paFunction(){
                window._piq.push(['trackEvent', 'rs2022::十大頻道::首頁', 'imps::最近好多人搜尋::hashtag_card::' + this.model_id, '更多超熱搜話題::http://curation.pixnet.net/' , '1.0001']);
            },
            paMoreFunction() {
                window._piq.push(['trackEvent', 'rs2022::十大頻道::首頁', 'click::最近好多人搜尋::hashtag_card::' + this.model_id, '更多超熱搜話題::http://curation.pixnet.net/' , '1.0001']);
            }
        },
        mounted() {
            this.paFunction();
        },
    }
