
import { Swiper, Navigation } from "swiper";
import { mapActions } from 'vuex';
import TagCapsule from './TagCapsule.vue';

export default {
    name: "tag-all-site-container",
    components: { TagCapsule },
    data() {
        return {
            swiper: ''
        }
    },
    props: {
        pa_page_name: {
            type: String
        }
    },
    computed: {
        tag_data() {
            return this.$store.state.tag.all_site_tag_data;
        },
        model_id() {
            return this.$store.state.tag.all_site_model_id;
        }
    },
    methods: {
        ...mapActions('tag', [
            'getIndexTagData'
        ]),
        paFunction(first_index) {
            for(let i = first_index; i < first_index + 5; i ++) { 
                let pa_idx = (i + 1)/10000; 
                let pa_e_value = Math.floor((first_index + 5)/5) + pa_idx;
                window._piq.push(['trackEvent', 'rs2022::十大頻道::首頁', 'imps::最近好多人搜尋::hashtag_card::' + this.model_id, '最近好多人搜尋::' + this.tag_data[i].title, pa_e_value]);
            }
        },
    },
    async fetch() {
        await this.getIndexTagData();
    },
    async mounted() {
        Swiper.use([Navigation]);
        this.swiper = new Swiper(".tag-swiper", {
            direction: "horizontal",
            breakpoints: {
                769: {
                    slidesPerGroup: 5,
                }
            },
            slidesPerView: 'auto',
            modules: [Navigation],
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            }
        });
        this.swiper.on('slideChange', () => {
            this.paFunction(this.swiper.realIndex);
        });
        if (this.tag_data.length > 0) {
            this.paFunction(0);
        } else {
            await this.getIndexTagData();
            await this.paFunction(0);
        }
    },
}
