
import { mapActions, mapMutations } from 'vuex';
import ShortArticleCard from './ShortArticleCard.vue';
import ShortArticleReport from './lightbox/ShortArticleReport.vue';
import ArticleReportResponse from './lightbox/ArticleReportResponse.vue';
import ArticleShare from './lightbox/ArticleShare.vue';
import ShortArticleCheckToDelete from './lightbox/ShortArticleCheckToDelete.vue';
import ToastContainer from '../lightbox/ToastContainer.vue';
export default {
    name: "short-article-container",
    components: { ShortArticleCard, ToastContainer, ShortArticleReport, ArticleShare, ArticleReportResponse, ShortArticleCheckToDelete },
    data() {
        return {
            article_page: 2,
            pa_count: 1,
        }
    },
    methods: {
        ...mapActions('short_article', [
            'getShortArticleData',
            'getMoreShortArticleData'
        ]),
        ...mapMutations('short_article', [
            'toogleLazyLoadingStatus'
        ]),
        openToast(){
            this.$refs.toast_box.openToastElement();
        },
        clickToGetMoreArticle(){
            this.toogleLazyLoadingStatus();
            const token = this.member_token ? this.member_token : "";
            const params = {
                page_size: 20,
                member_token: token,
                cursor: this.cursor_id,
            };
            this.getMoreShortArticleData(params);
            this.article_page = this.article_page + 1;
            this.pa_count = this.pa_count + 1;
        },
    },
    computed: {
        member_token() {
            return this.$store.state.login.pix_member_token.member_token;
        },
        article_model_id_index() {
            return this.$store.state.article.model_id_index;
        },
        cursor_id() {
            return this.$store.state.short_article.cursor_id;
        },
        lazy_loading_status() {
            return this.$store.state.short_article.lazy_loading_status;
        },
        all_data() {
            return this.$store.state.short_article.short_article_all;
        },
        odd_column_data() {
            return this.all_data.filter((v, i) => !(i % 2));
        },
        even_column_data() {
            return this.all_data.filter((v, i) => i % 2);
        },
    },
    async mounted() {
        const token = this.member_token ? this.member_token : "";
        const params = {
            page_size: 20,
            member_token: token,
            cursor: 0,
        };
        await this.getShortArticleData(params);
        // console.log(this.all_data)
    }
}
