
    import { mapActions } from 'vuex';
    import CollectionCard from './CollectionCard.vue';

    export default {
    name: "article-list-card-container",
    components: { CollectionCard },
    props: {
        item: {
            type: Object
        }
    },
    methods: {
        ...mapActions('article_list', [
            'getRandomArticleListData'
        ])
    },
    data(){
        return {
            pa_count: 1,
            isVisible: true
        }
    },
    computed: {
        article_collection() {
            return this.$store.state.article_list.random_list_data.slice(0 ,5);
        },
        article_collection_title() {
            return this.$store.state.article_list.random_list_title;
        },
        article_collection_link() {
            return this.$store.state.article_list.random_list_link;
        },
        article_model_id() {
            return this.$store.state.article_list.list_model_id;
        }
    },
    async fetch(){
        await this.$store.dispatch("article_list/getRandomArticleListData");
    },
    methods: {
        async randomList() {
            await this.$store.dispatch("article_list/getRandomArticleListData");
            this.addPaCount();
            this.paRunfunction();
        },
        imgError(img) {
            img.src = require(`~/static/img/public/default/img_default_`+ Math.floor(Math.random()*3) +`.png)`);
        },
        addPaCount() {
            this.pa_count = this.pa_count + 1;
        },
        paFunction(link, index){
            let pa_idx = index/10000;
            window._piq.push(['trackEvent', 'rs2022::十大頻道::首頁', 'imps::熱門頻道文章::article_card::'+ this.article_model_id, '熱門' + this.article_collection_title + '文章::' + link, this.pa_count + pa_idx]);
        },
        paClickFunction(link, index) {
            let idx = ((this.pa_count - 1) * 5 + index);
            let pa_idx = idx/10000;
            window._piq.push(['trackEvent', 'rs2022::十大頻道::首頁', 'click::熱門頻道文章::article_card::'+ this.article_model_id, '熱門' + this.article_collection_title + '文章::' + link, this.pa_count + pa_idx]);
        },
        paRunfunction() {
            const item = this.article_collection;
            for(let i = 1; i < 6; i++) {
                if (item[i - 1]) {
                    let reg_link = 'http://'+ item[i - 1].author.user_name +'.pixnet.net/blog/post/' + item[i - 1].id
                    this.paFunction(reg_link, ((this.pa_count - 1) * 5 + i));
                }
            }
        },
        visibilityChanged(isVisible, entry) {
            this.isVisible = isVisible
            if (entry.intersectionRatio > 0) {
                this.paRunfunction();
            }
        }
    }
}
