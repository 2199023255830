
export default {
  props: {
    dialogLeft: {
      type: Number,
    },
    dialogTop: {
      type: Number,
    },
    publishTop: {
      type: Number,
    },
  },
  data() {
    return {
      isNextStep: false,
    };
  },
  methods: {
    goToNext() {
      const vm = this;
      vm.isNextStep = true;
    },
    closeStep() {
      const vm = this;
      localStorage.setItem("isShowWelcome", 1);
      vm.$emit("closeWelcome");
    }
  },
};
