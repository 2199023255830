
import { mapMutations } from "vuex";
import GAContainer from "~/components/common/GAContainer.vue";
import MainContainer from "~/components/main/mainContaner/MainContainer.vue";
import RightContainer from "~/components/main/rightContainer/RightContainer.vue";
import IntroDialog from "@/components/main/introDialog";
import { TITLE, DESCRIPTION, KEYWORDS, OG_IMAGE } from "~/utils/const";

export default {
  name: "IndexPage",
  components: {
    MainContainer,
    RightContainer,
    GAContainer,
    IntroDialog,
  },
  head() {
    return {
      title: TITLE,
      meta: [
        { hid: "description", name: "description", content: DESCRIPTION },
        {
          hid: "og:description",
          name: "og:description",
          property: "og:description",
          content: DESCRIPTION,
        },
        {
          hid: "twitter:description",
          name: "twitter:description",
          property: "twitter:description",
          content: DESCRIPTION,
        },
        { hid: "keywords", name: "keywords", content: KEYWORDS },
        {
          hid: "og:title",
          name: "og:title",
          property: "og:title",
          content: TITLE,
        },
        { property: "og:type", property: "og:type", content: "website" },
        { property: "og:image", property: "og:image", content: OG_IMAGE },
        {
          property: "og:image:secure_url",
          property: "og:image:secure_url",
          content: OG_IMAGE,
        },
        {
          property: "twitter:image",
          property: "twitter:image",
          content: OG_IMAGE,
        },
        {
          property: "image",
          name: "image",
          itemprop: "image",
          content: OG_IMAGE,
        },
      ],
      link: [
        { rel: "canonical", href: "https://www.pixnet.net/", key: "canonical" },
      ],
    };
  },
  jsonld() {
    return {
      "@context": " https://schema.org/",
      "@graph": [
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          url: "https://www.pixnet.net",
          logo: OG_IMAGE,
          name: "痞客邦",
          "@id": "https://www.pixnet.net/#organization",
          description: DESCRIPTION,
          address: "",
          brand: {
            "@type": "Brand",
            logo: OG_IMAGE,
          },
          sameAs: "https://www.facebook.com/pixnet.net",
        },
        {
          "@context": "https://schema.org/",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              name: TITLE,
              item: " https://www.pixnet.net",
            },
          ],
        },
        {
          "@context": "https://schema.org",
          "@type": "WebSite",
          "url": "https://www.pixnet.net/",
          "potentialAction": {
            "@type": "SearchAction",
            "target": {
              "@type": "EntryPoint",
              "urlTemplate": "https://curation.pixnet.net/{search_term_string}",
            },
            "query-input": "required name=search_term_string",
          },
        },
      ],
    };
  },
  data() {
    return {
      pa_page_name: "首頁",
      isMobile: false,
      isShowIntro: false,
      dialogLeft: 0,
      dialogTop: 0,
      publishTop: 0,
    };
  },
  methods: {
    ...mapMutations("tab", ["updateTabId"]),
    paFunction() {
      window._piq.push(
        ["setCustomVar", "venue", "93-62ce92c0c8add"],
        ["setCustomVar", "visitor_id", ""],
        ["setCustomVar", "member_uniqid", ""],
        ["trackPageView"]
      );

      // 這裡開始是各別要追蹤的行為
      window._piq.push([
        "trackEvent",
        "rs2022::十大頻道::首頁",
        "imps::頁面::page::user_action",
        "",
        "",
      ]);
    },
    paScrollFunction() {
      addEventListener(
        "scroll",
        () => {
          window._piq.push([
            "trackEvent",
            "rs2022::十大頻道::首頁",
            "scroll::頁面::page::user_action",
            "",
            "",
          ]);
        },
        { once: true }
      );
    },
    gtag() {
      window.dataLayer.push(arguments);
    },
    activeGaPage() {
      this.gtag("config", "G-RPGK0ML6QM", {
        channel_category: "首頁",
      });
      this.gtag("config", "G-45SMZ66RLZ", {
        channel_category: "首頁",
      });
    },
    activeMcPage() {
      (function (c, l, a, r, i, t, y) {
        c[a] =
          c[a] ||
          function () {
            (c[a].q = c[a].q || []).push(arguments);
          };
        t = l.createElement(r);
        t.async = 1;
        t.src = "https://www.clarity.ms/tag/" + i;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
      })(window, document, "clarity", "script", "kbv47mjn0y");
    },
    checkIsMobile() {
      this.showIntroDialog();
      this.isMobile = window.innerWidth < 768;
      if (this.isMobile) this.$router.push("/v3mobile");
    },
    showIntroDialog() {
      const vm = this;
      if (process.client) {
        const tmpWelcome = vm.$route.query.welcome;
        if (tmpWelcome) {
          const isShowWelcome = localStorage.getItem("isShowWelcome");
          if (!isShowWelcome) {
            vm.dialogLeft = (window.innerWidth - 300) / 2;
            vm.dialogTop = (window.innerHeight - 645) / 2;
            vm.publishTop = vm.dialogTop + 410;
            setTimeout(() => {
              vm.isShowIntro = true;
            }, 0);
          }
        }
      }
    },
    closeWelcome() {
      const vm = this;
      vm.isShowIntro = false;
    },
  },
  mounted() {
    const vm = this;
    vm.showIntroDialog();
    window.dataLayer = window.dataLayer || [];
    this.activeGaPage();
    this.activeMcPage();
    this.updateTabId(1);
    this.paFunction();
    this.paScrollFunction();
    this.$cookies.remove("pixdata");
    this.$cookies.remove("read_article_list");
    this.isMobile = window.innerWidth < 768;
    if (this.isMobile) this.$router.push("/v3mobile");
    window.addEventListener("resize", this.checkIsMobile);
  },
  beforeDestroy() {
    // 移除resize事件的監聽
    window.removeEventListener("resize", this.checkIsMobile);
  },
};
