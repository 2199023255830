
import ArticleHotIndexCard from "./ArticleHotIndexCard.vue";

import { Swiper, Navigation } from "swiper";
import ArticleHotIndexDesktopCard from "./ArticleHotIndexDesktopCard.vue";
export default {
    data() {
        return {
            swiper: "",
            pa_count: 1
        };
    },
    components: {
        ArticleHotIndexCard,
        
    },
    computed: {
        window_width() {
            return this.$store.state.index_tab.window_width;
        },
        day_hot_article_full() {
            return this.$store.state.article.day_hot_article_full;
        },
        day_hot_article_desktop() {
            return this.$store.state.article.day_hot_article_full.slice(0, 5);
        }
    },
    mounted() {
        Swiper.use([Navigation]);
        this.swiper = new Swiper(".hot-index-swiper", {
            direction: "horizontal",
            observer: true,
            breakpoints: {
                769: {
                    slidesPerGroup: 1,
                    slidesPerView: 3,
                    spaceBetween: 16,
                }
            },
            slidesPerView: 1.4,
            slidesPerColumn: 2,
            slidesPerColumnFill: "row",
            spaceBetween: 8,
            modules: [Navigation],
        });
    },
    async fetch() {
        const item = { sort: "hot", type: "all", pre_page: 10, ver: 2 };
        await this.$store.dispatch("article/getDayHotArticle", item);
    },
    components: { ArticleHotIndexCard, ArticleHotIndexDesktopCard }
}
