
import { Swiper, Autoplay } from "swiper";
import { mapActions } from 'vuex';
export default {
    name: "advertise-container",
    computed: {
        advertise_list() {
            return this.$store.state.advertise.advertise_list;
        },
        advertise_list_desktop() {
            return this.$store.state.advertise.advertise_list_desktop;
        }
    },
    data() {
        return {
            pa_count: 1,
        };
    },
    methods: {
        ...mapActions('advertise', [
            'getAdvertiseList'
        ]),
        setSwiper() {
            Swiper.use([Autoplay]);
            this.swiper = new Swiper(".advertise", {
                direction: "horizontal",
                observer: true,
                centeredSlides: true,
                loop: true,
                loopedSlides: 50,
                modules: [Autoplay],
                autoplay: {
                    delay: 3000,
                }
            });
        },
        paFunction(id, link, idx) {
            let reg_link = link.replace('https://', 'http://');
            window._piq.push(['trackEvent', 'rs2022::十大頻道::首頁', 'imps::活動::article_card::' + id, '活動::' + reg_link, '1.000' + idx]);
        },
        paClickFunction(id, link, idx) {
            let reg_link = link.replace('https://', 'http://');
            window._piq.push(['trackEvent', 'rs2022::十大頻道::首頁', 'click::活動::article_card::' + id, '活動::' + reg_link, '1.000' + idx]);
        }
    },
    async fetch() {
        await this.getAdvertiseList();
    },
    updated() {
        this.setSwiper();
        if(this.advertise_list.length > 0) {
            this.paFunction(this.advertise_list[0].id, this.advertise_list[0].url, 1);
        }
        this.swiper.on('slideChange', () => {
            this.pa_count = this.pa_count + 1;
            if (this.pa_count < 5) {
                this.paFunction(this.advertise_list[this.swiper.realIndex].id, this.advertise_list[this.swiper.realIndex].url, this.swiper.realIndex + 1);
            }
        });
    }
}
