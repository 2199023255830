
import axios from "axios";
import { mapMutations, mapActions } from 'vuex';
import MixArticleLoading from "../article/MixArticleLoading.vue";
export default {
    name: "video-index-container",
    components: { MixArticleLoading },
    data() {
        return {
            isLoading: true,
            isDataFetching: false,
            throttleTimer: undefined
        }
    },
    computed: {
        video_data() {
            return this.$store.state.video.video_data;
        },
        emptyCardNum() {
            switch (this.video_data.length % 4) {
                case 2:
                    return 2;
                case 3:
                    return 1;
                default:
                    return 0;
            }
        }
    },
    methods: {
        ...mapMutations('video', [
            'setVideoData'
        ]),
        ...mapActions('video', [
            'getVideos'
        ]),
        async getVideoData() {
            this.isDataFetching = true;
            this.isLoading = true;
            await this.getVideos({
                random: 1,
                isAppend: true
            });
            this.isDataFetching = false;
            this.isLoading = false;
        },
        handleInfiniteScroll(e) {
            this.throttle(() => {
                if (window.innerHeight + window.pageYOffset > document.body.offsetHeight * 0.7 && !this.isDataFetching) {
                    this.getVideoData();
                }
            }, 500);
        },
        throttle(callback, time) {
            if (this.throttleTimer) return;
            this.throttleTimer = true;
            setTimeout(() => {
                callback();
                this.throttleTimer = false;
            }, time);
        },
    },
    async created() {
        this.setVideoData({
            data: []
        });
        await this.getVideoData();
        window.addEventListener("scroll", this.handleInfiniteScroll);
    },
    beforeDestroy() {
        // 移除滾動事件的監聽
        window.removeEventListener("scroll", this.handleInfiniteScroll);
    }
}
